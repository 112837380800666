var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"nodeForm",attrs:{"rules":_vm.rules,"label-position":"top","label-width":"100px","model":_vm.nodeToBind}},[_c('el-form-item',{attrs:{"prop":"node_name","label":_vm.__('Name')}},[_c('el-input',{model:{value:(_vm.nodeToBind.node_name),callback:function ($$v) {_vm.$set(_vm.nodeToBind, "node_name", $$v)},expression:"nodeToBind.node_name"}})],1),_c('el-tabs',{staticClass:"tabs",staticStyle:{"font-size":"1.2em"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":_vm.__('Grammar'),"name":"grammar"}},[_c('el-scrollbar',{attrs:{"native":false}},[_c('el-form-item',{staticClass:"is-required",attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.language_id","label":_vm.__('Language')}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.__('Please select a language'),"default-first-option":"","filterable":"","clearable":"","loading-text":_vm.__('fetching languages'),"no-data-text":_vm.noText},model:{value:(
                _vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .language_id
              ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  , "language_id", $$v)},expression:"\n                nodeToBind.menu_node.data.user_input_nodes_setting.data\n                  .language_id\n              "}},_vm._l((_vm.filteredLanguages),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.language_name,"value":item.language_id}})}),1)],1),_c('el-form-item',{staticClass:"is-required",attrs:{"prop":"grammar_condition","label":_vm.__('Grammar Conditions')}},[_c('match-grammar',{attrs:{"gotoOptions":_vm.generateGotoOptions},model:{value:(_vm.grammarConditions),callback:function ($$v) {_vm.grammarConditions=$$v},expression:"grammarConditions"}})],1)],1)],1),_c('el-tab-pane',{attrs:{"label":_vm.__('Text-to-Speech'),"name":"tts_settings"}},[_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.prompt_text","label":_vm.__('Prompt')}},[_c('input-variable-popper',{staticClass:"promptEditor",attrs:{"value":_vm.promptText,"is-content-editable":"","is-text-area":false,"include-prompts":"","include-audio-variables":"","include-secure-variables":false,"include-payments":"","ats":_vm.getOptionsInitiators,"popper-class":"prompt-node-popper","scroll-ref":".node-modal-menu"},on:{"input":function($event){return _vm.updateText('prompt_text', $event)}}}),_c('audio-player',{staticClass:"audio-player",attrs:{"disabled":!_vm.promptText,"show-reload":_vm.promptContentChanged,"generating-audio":_vm.generatingAudioForPrompt,"file":_vm.promptAudioFile},on:{"get-preview":function($event){return _vm.generateAudio(
                'promptText',
                'generatingAudioForPrompt',
                'promptAudioFile',
                'promptAtAudioFileCreation'
              )}}})],1),_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.fallback_prompt_text","label":_vm.__('Fallback Prompt')}},[_c('input-variable-popper',{staticClass:"promptEditor",attrs:{"value":_vm.fallbackPromptText,"is-content-editable":"","is-text-area":false,"include-prompts":"","include-audio-variables":"","include-secure-variables":false,"include-payments":"","ats":_vm.getOptionsInitiators,"popper-class":"prompt-node-popper","scroll-ref":".node-modal-menu"},on:{"input":function($event){return _vm.updateText('fallback_prompt_text', $event)}}}),_c('audio-player',{staticClass:"audio-player",attrs:{"disabled":!_vm.fallbackPromptText,"show-reload":_vm.fallbackPromptContentChanged,"generating-audio":_vm.generatingAudioForFallbackPrompt,"file":_vm.fallbackPromptAudioFile},on:{"get-preview":function($event){return _vm.generateAudio(
                'fallbackPromptText',
                'generatingAudioForFallbackPrompt',
                'fallbackPromptAudioFile',
                'fallbackPromptAtAudioFileCreation'
              )}}})],1),_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.no_input_prompt_text","label":_vm.__('No Input Prompt')}},[_c('input-variable-popper',{staticClass:"promptEditor",attrs:{"value":_vm.noInputPromptText,"is-content-editable":"","is-text-area":false,"include-prompts":"","include-audio-variables":"","include-secure-variables":false,"include-payments":"","ats":_vm.getOptionsInitiators,"popper-class":"prompt-node-popper","scroll-ref":".node-modal-menu"},on:{"input":function($event){return _vm.updateText('no_input_prompt_text', $event)}}}),_c('audio-player',{staticClass:"audio-player",attrs:{"disabled":!_vm.noInputPromptText,"show-reload":_vm.noInputPromptContentChanged,"generating-audio":_vm.generatingAudioForNoInputPrompt,"file":_vm.noInputPromptAudioFile},on:{"get-preview":function($event){return _vm.generateAudio(
                'noInputPromptText',
                'generatingAudioForNoInputPrompt',
                'noInputPromptAudioFile',
                'noInputPromptAtAudioFileCreation'
              )}}})],1),_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.no_match_prompt_text","label":_vm.__('No Match Prompt')}},[_c('input-variable-popper',{staticClass:"promptEditor",attrs:{"value":_vm.noMatchPromptText,"is-content-editable":"","is-text-area":false,"include-prompts":"","include-audio-variables":"","include-secure-variables":false,"include-payments":"","ats":_vm.getOptionsInitiators,"popper-class":"prompt-node-popper","scroll-ref":".node-modal-menu"},on:{"input":function($event){return _vm.updateNoMatchPromptText($event)}}}),_c('audio-player',{staticClass:"audio-player",attrs:{"disabled":!_vm.noMatchPromptText,"show-reload":_vm.noMatchPromptContentChanged,"generating-audio":_vm.generatingAudioForNoMatchPrompt,"file":_vm.noMatchPromptAudioFile},on:{"get-preview":function($event){return _vm.generateAudio(
                'noMatchPromptText',
                'generatingAudioForNoMatchPrompt',
                'noMatchPromptAudioFile',
                'noMatchPromptAtAudioFileCreation'
              )}}})],1)],1),_c('el-tab-pane',{attrs:{"label":_vm.__('Speech Recognizer'),"name":"sr_settings"}},[_c('el-scrollbar',{attrs:{"native":false}},[_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.barge_in","label":_vm.__('Speech Controls')}},[_c('el-checkbox',{staticStyle:{"margin-left":"2px"},model:{value:(
                _vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .barge_in
              ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  , "barge_in", $$v)},expression:"\n                nodeToBind.menu_node.data.user_input_nodes_setting.data\n                  .barge_in\n              "}},[_vm._v(_vm._s(_vm.__("Barge In"))+" ")]),_c('el-checkbox',{staticStyle:{"margin-left":"2px"},model:{value:(
                _vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .dtmf_only
              ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  , "dtmf_only", $$v)},expression:"\n                nodeToBind.menu_node.data.user_input_nodes_setting.data\n                  .dtmf_only\n              "}},[_vm._v(_vm._s(_vm.__("Disable Voice Input")))])],1),_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.confidence_level","label":_vm.__('Minimum Transcription Confidence Score')}},[_c('el-input-number',{attrs:{"precision":2,"min":0.0,"step":0.05,"max":1,"step-strictly":false},model:{value:(
                _vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .confidence_level
              ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  , "confidence_level", $$v)},expression:"\n                nodeToBind.menu_node.data.user_input_nodes_setting.data\n                  .confidence_level\n              "}})],1),_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.interdigit_timeout","label":_vm.__('Interdigit Timeout (Seconds)')}},[_c('el-input-number',{attrs:{"precision":1,"min":0.5,"step":0.5,"max":10,"step-strictly":true},model:{value:(
                _vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .interdigit_timeout
              ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  , "interdigit_timeout", $$v)},expression:"\n                nodeToBind.menu_node.data.user_input_nodes_setting.data\n                  .interdigit_timeout\n              "}})],1)],1)],1),_c('el-tab-pane',{attrs:{"label":_vm.__('Event Handler'),"name":"event_handler"}},[_c('el-scrollbar',{attrs:{"native":false}},[_c('div',{staticStyle:{"max-height":"90vh"}},[_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.no_input_canvas_id"}},[_c('EventHandlers',{attrs:{"event-handler-canvas":_vm.noInputEventHandlerCanvas,"count":_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .no_input_count,"eventHandlerLabel":_vm.__('No Input Event Handler'),"event-handler-place-holder":_vm.__('Select or create a no input event handler canvas'),"cssClass":"is-required"},on:{"update-event-handler":_vm.setNoInputEventHandlerCanvas,"update-event-handler-count":function($event){return _vm.setNoInputEventHandlerCount($event)}}})],1),_c('el-form-item',{attrs:{"prop":"menu_node.data.user_input_nodes_setting.data.no_match_canvas_id"}},[_c('EventHandlers',{attrs:{"event-handler-canvas":_vm.noMatchEventHandlerCanvas,"count":_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .no_match_count,"eventHandlerLabel":_vm.__('No Match Event Handler'),"event-handler-place-holder":_vm.__('Select or create a no match event handler canvas'),"cssClass":"is-required"},on:{"update-event-handler":_vm.setNoMatchEventHandlerCanvas,"update-event-handler-count":function($event){return _vm.setNoMatchEventHandlerCount($event)}}})],1)],1)])],1),_c('el-tab-pane',{attrs:{"label":_vm.__('Advanced ASR Settings'),"name":"advanced_asr_settings"}},[_c('el-scrollbar',{attrs:{"native":false}},[_c('div',{staticStyle:{"max-height":"90vh"}},[_c('advanced-speech-parameters',{attrs:{"nlp-engine":this.form_type_obj_temp.nlp_engine,"speech-complete-timeout":_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .speech_complete_timeout,"speech-incomplete-timeout":_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .speech_incomplete_timeout,"no-input-timeout":_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .no_input_timeout,"barge-in-sensitivity":_vm.nodeToBind.menu_node.data.user_input_nodes_setting.data
                  .barge_in_sensitivity,"node-type":'menuNode'},on:{"update-nlp-parameter":_vm.updateNlpParameters}})],1)])],1),_c('el-tab-pane',{attrs:{"label":_vm.__('Confirmation'),"name":"confirm_settings"}},[_c('speech-input-confirmation',{attrs:{"taskId":_vm.task_id,"documentRule":_vm.inputNodeSetting,"showNoInputTimeout":true,"scroll-ref":".node-modal-menu"},on:{"update-max-number-of-input-count":function($event){return _vm.setMaxNumberOfInputCount($event)}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }