<template>
  <div class="matchGrammarTable">
    <el-table
      class="variable-rules"
      fit
      :data="grammarMatches"
      style="width: 100%;"
    >
      <el-table-column prop="dtmf" :label="__('DTMF')">
        <template slot-scope="scope">
          <el-row type="flex">
            <el-col>
              <el-form-item :error="scope.row.dtmf_error">
                <el-input
                  v-model="scope.row.dtmf"
                  v-permit="numbersOrStar"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="grammar"
        :label="__('Grammar(Example: one||two||three)')"
      >
        <template slot-scope="scope">
          <el-row type="flex">
            <el-col>
              <el-form-item :error="scope.row.grammar_error">
                <el-input
                  v-model="scope.row.grammar"
                  v-permit="alphaNumericGrammarRegex"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="node_name" :label="__('Goto Node')">
        <template slot-scope="scope">
          <el-row
            :gutter="20"
            class="goto-node-configure"
            type="flex"
            justify="start"
          >
            <el-col>
              <el-form-item :error="scope.row.node_name_error">
                <create-or-select
                  :items="extractGotoNodeOptions"
                  :current_select="currentSelection(scope.row)"
                  :fail-to-create-message="scope.row.error"
                  new-item-message="new node"
                  :placeholder="__('Node Name')"
                  @change="handleChange($event)(scope.row)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column class-name="row-message" width="100px" prop="msg" />
      <el-table-column class-name="cell-item-pointer" width="40px">
        <template slot-scope="scope">
          <span @click="removeGrammarMatch(scope.$index)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";
import { alphaNumericGrammarRegex, numbersOrStar } from "@/utils/regex";

export default {
  components: {
    CreateOrSelect
  },
  props: {
    value: {
      required: true,
      type: Array
    },
    gotoOptions: {
      required: true,
      type: Array
    }
  },
  data() {
    const grammar_match = {
      dtmf: "",
      grammar: "",
      node_name: "",
      node_id: -1
    };
    return {
      grammar_match,
      $grammar_matches: [{ ...grammar_match }],
      $gotoOptions: [],
      numbersOrStar: numbersOrStar,
      alphaNumericGrammarRegex: alphaNumericGrammarRegex
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),

    extractGotoNodeOptions() {
      const options = _.map(this.gotoOptions, child => ({
        label: child.node_name,
        value: child.node_id
      }));
      return this.$data.$gotoOptions.concat(options);
    },

    grammarMatches() {
      return this.$data.$grammar_matches;
    },

    currentSelection() {
      return row => {
        const { node_id, node_name } = row;
        return node_id === -1 ? node_name : node_id;
      };
    }
  },
  methods: {
    addNewGrammarMatch() {
      this.$data.$grammar_matches.push({ ...this.grammar_match });
    },

    removeGrammarMatch(index) {
      this.$data.$grammar_matches.splice(index, 1);
    },

    initializeGrammarMatches(grammarMatches) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(grammarMatches) && grammarMatches.length) {
          this.$data.$grammar_matches = _.cloneDeep(grammarMatches);
        }
        if (
          !_.some(
            this.$data.$grammar_matches,
            grammarMatch => !grammarMatch.dtmf.length
          ) ||
          !_.some(
            this.$data.$grammar_matches,
            grammarMatch => !grammarMatch.grammar.length
          ) ||
          !_.some(
            this.$data.$grammar_matches,
            grammarMatch => !grammarMatch.node_name.length
          )
        ) {
          this.addNewGrammarMatch();
          this.$emit("input", _.cloneDeep(this.$data.$grammar_matches));
        }
      }
    },

    handleChange(option) {
      return row => {
        this.$set(row, "node_id", option.value);
        this.$set(row, "node_name", option.label);
        this.$set(row, "msg", option.msg);
      };
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeGrammarMatches"
    },
    grammarMatches: {
      handler: function(grammarMatches) {
        this.$emit("input", _.cloneDeep(grammarMatches));
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
/*.goto-node-configure {*/
/*  flex-direction: column;*/
/*}*/
/*.goto-node-configure .el-form-item {*/
/*  margin-bottom: 0;*/
/*}*/

/*.goto-node-configure ::v-deep .el-form-item__content {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: space-between;*/
/*}*/

/*.goto-node-configure ::v-deep .el-form-item__error {*/
/*  flex-grow: 1;*/
/*  flex-shrink: 0;*/
/*  align-self: center;*/
/*}*/

.matchGrammarTable ::v-deep .el-table--medium td {
  padding: 0;
}

.matchGrammarTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    /*padding-top: 4px;*/
  }
}

.matchGrammarTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.matchGrammarTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.variable-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.matchGrammarTable ::v-deep .el-table::before {
  background-color: white !important;
}

.matchGrammarTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}
</style>
